import * as React from 'react';
import cn from 'classnames';
import Button from 'styleguide/components/Button/Button';
import { formatMoney } from 'utils/money';
import Loader from 'styleguide/components/Loader/Loader';
import { LineItem, Promotion } from 'api/orders/types';
import Span from 'styleguide/components/Span/Span';
import ErrorMessage from 'styleguide/components/forms/ErrorMesssage/ErrorMessage';
import OrderContext from 'contexts/OrderContext/OrderContext';
import A from 'styleguide/components/Links/A';
import AppContext from 'contexts/AppContext/AppContext';
import Image from 'styleguide/components/Image/Image';
import Collapse from 'styleguide/components/Collapse/Collapse';
import TempOrderTotalsContext from 'contexts/TempOrderTotalsContext/TempOrderTotalsContext';
import Action from 'app/styleguide/components/Action/Action';
import { FaTimes } from 'react-icons/fa';
import { Formik } from 'formik';
import { object, string } from 'yup';
import { applyPromoCode, removePromoCode } from 'app/contexts/OrderContext/actions';
import { FormikFieldWrapper } from 'app/styleguide/components/Formik';
import ItemRow from './ItemRow';
import DiscountRow from './DiscountRow';

const NUMBER_OF_SHOWN_ITEMS = 3;

const OrderItemCard = ({ item }: { item: LineItem }) => {
  const appContext = React.useContext(AppContext);
  const quantity: number = parseInt(item.quoterJson.documentCount, 10);
  const pages: number = parseInt(item.quoterJson.documentPageCount, 10);
  const name: string = item.quoterJson.name !== '' ? item.quoterJson.name : null;

  const getProductImage = (productName: string) => {
    const product = appContext.store.products.find(prod => prod.name === productName);
    return product ? product.images.master[0].url.product : '';
  };

  return (
    <div className="mb-2 flex rounded-lg border border-solid border-neutral-200 bg-neutral-50 p-1 lg:p-2">
      <Image
        alt={item.displayName}
        url={getProductImage(item.product.name)}
        className="h-18 mr-2 w-14 object-contain lg:h-20 lg:w-16 lg:object-cover"
        data-cy={`itemCardImage-${item.id}`}
      />
      <div className="flex flex-col justify-center">
        <p
          data-cy={`itemCardDisplayName-${item.id}`}
          className="sub-heading-bold !m-0 line-clamp-2 text-lg lg:text-xl"
        >
          {item.displayName}
        </p>
        {pages > 0 ? (
          <p
            data-cy={`itemCardQuantity-${item.id}`}
            className="paragraph-medium-mobile !m-0 line-clamp-1 text-xs text-gray-500 lg:text-sm"
          >{`${quantity} qty x ${pages} pages`}</p>
        ) : (
          <p
            data-cy={`itemCardQuantity-${item.id}`}
            className="paragraph-medium-mobile !m-0 line-clamp-1 text-gray-500"
          >{`${quantity} qty`}</p>
        )}
        {!!name && (
          <p
            data-cy={`itemCardName-${item.id}`}
            className="paragraph-medium-mobile !m-0 line-clamp-2 text-gray-500"
          >
            {name}
          </p>
        )}
      </div>
    </div>
  );
};

interface Props {
  showPromoCodeField?: () => void;
  showPromo: boolean;
  promotions: Promotion[];
  onCheckoutClick?: () => void;
  checkoutDisabled?: boolean;
  buttonText: string;
  errorText?: string;
  hideButton?: boolean;
  disablePromoCodeForm?: boolean;
}
const PurchaseSummary = ({
  showPromoCodeField,
  showPromo,
  promotions,
  onCheckoutClick,
  checkoutDisabled,
  disablePromoCodeForm,
  buttonText,
  errorText,
  hideButton,
}: Props) => {
  const { order, dispatch } = React.useContext(OrderContext);
  const appContext = React.useContext(AppContext);
  const [summaryExpanded, setSummaryExpanded] = React.useState<boolean>(false);
  const tempOrderTotalsContext = React.useContext(TempOrderTotalsContext);
  const orderTotals = tempOrderTotalsContext?.orderTotals;

  return (
    <div className="relative block max-w-sm rounded-lg bg-white shadow-lg -md:max-w-full">
      {order.status === 'loading' && (
        <div className="absolute inset-0 bg-white bg-opacity-80 text-center flex justify-center z-10 pt-32">
          <Loader dataCy="purchaseSummaryLoadingSpinner" size="xl" />
        </div>
      )}
      <div className="flex flex-col p-6">
        <p
          data-cy="orderSummmaryTitle"
          className="m-0 p-0 font-hvMedium text-sm uppercase leading-5 text-gray-700"
        >{`Order Summary (${order.lineItems.length}) - #${order.number}`}</p>
        {order.state !== 'cart' && order.state !== 'address' && (
          <A
            href="/orders/quote.pdf"
            underline="none"
            title="Save Quote"
            targetBlank
            className="!leading-1 !text-xs uppercase"
            color="blue"
            data-cy="saveQuoteBtn"
            disabled={!(typeof order.status === 'string' || order.status instanceof String)}
          >
            Save Quote
          </A>
        )}
        <div className="mb-6 flex flex-col border-b border-solid border-gray-50 py-6">
          {order.lineItems &&
            order.lineItems
              .slice(0, NUMBER_OF_SHOWN_ITEMS)
              .map(item => <OrderItemCard item={item} key={item.id} />)}
          {order.lineItems && order.lineItems.length > NUMBER_OF_SHOWN_ITEMS && (
            <Collapse>
              <Collapse.Item
                title={
                  <div
                    className="paragraph-medium-mobile mr-2 text-gray-500"
                    data-cy="expandAndShrinkItemsBtnInOrderSummary"
                  >
                    {summaryExpanded
                      ? 'Close'
                      : `See Remaining ${order.lineItems.length - NUMBER_OF_SHOWN_ITEMS}  Items`}
                  </div>
                }
                classNameBody="!bg-shades-0 !pt-0 !px-0 overflow-hidden"
                onToggle={() => setSummaryExpanded(!summaryExpanded)}
                fixedIcon={false}
                isWide
                withPadding
                showMore
                centeredTitle
                iconClassName="!h-2 !w-2 flex"
                iconColor="darkGray"
                body={
                  order.lineItems &&
                  order.lineItems
                    .slice(NUMBER_OF_SHOWN_ITEMS)
                    .map(item => <OrderItemCard item={item} key={item.id} />)
                }
              />
            </Collapse>
          )}
        </div>
        <ItemRow
          valueDataCy="summarySubtotal"
          className="mb-6"
          title="Subtotal"
          value={formatMoney(orderTotals?.subtotal || order.subtotal)}
          noBorder
        />
        <ItemRow
          valueDataCy="summaryShipping"
          className="mb-6"
          title="Shipping &amp; Handling"
          value={
            orderTotals?.shippingTotal || order.shippingAddress
              ? formatMoney(orderTotals?.shippingTotal || order.shippingTotal)
              : 'Fill ZIP'
          }
          noBorder
        />
        {promotions.map(elem => (
          <div className="text-center mb-6 flex justify-center items-center">
            <Span data-cy="promotionApplied">
              Promo Applied:{' '}
              <Span className="mb-2 rounded-lg border border-solid border-neutral-200 bg-neutral-50 p-1 lg:p-2">
                {elem.promoCode}
              </Span>
            </Span>
            <Action
              classes="ml-2"
              tooltipClassName="!flex"
              icon={FaTimes}
              action="remove"
              onClick={() => {
                removePromoCode(dispatch, elem.promoCode || order.promotions[0].promoCode);
              }}
              disabled={disablePromoCodeForm}
              disabledMessage="Please go back to delivery step to remove promo code."
              iconColor="#aaa"
              iconSize="22"
              hoverColor="red"
              dataCy="promoCodeRemoveBtn"
            />
          </div>
        ))}
        {(order.promotions.length !== 0 || !showPromo) && (
          <DiscountRow
            order={order}
            orderTotals={orderTotals}
            onAddPromocodeClick={showPromoCodeField}
            hasPromotionsApplied={promotions.length > 0}
            disabled={
              disablePromoCodeForm ||
              checkoutDisabled ||
              !(typeof order.status === 'string' || order.status instanceof String)
            }
          />
        )}
        {showPromo && order.promotions.length === 0 && !appContext.store.admin && (
          <div className="mb-6 border-b border-solid border-gray-50 pb-6 w-full">
            <div
              title={disablePromoCodeForm ? 'Please go back to delivery step to apply a promo code.' : ''}
              className={cn('flex pt-1 pb-3', 'w-max', disablePromoCodeForm && 'cursor-not-allowed')}
            >
              <Formik
                initialValues={{ promoCodeInput: '' }}
                onSubmit={values => {
                  applyPromoCode(dispatch, values.promoCodeInput);
                }}
                validationSchema={object().shape({
                  promoCodeInput: string().required(''),
                })}
              >
                {formikProps => (
                  <>
                    <FormikFieldWrapper
                      componentType="input"
                      labelClassName="!font-hvLite"
                      name="promoCodeInput"
                      data-cy="promoCodeInput"
                      size="md"
                      labelComponent="Enter Promo Code"
                      required
                      disabled={disablePromoCodeForm}
                      className={`border-r-none ${disablePromoCodeForm ? 'cursor-not-allowed' : ''}`}
                    />
                    <Button
                      dataCy="promoCodeApplyBtn"
                      type="submit"
                      color="blue"
                      disabled={disablePromoCodeForm}
                      className={`z-10 -ml-2 !rounded-l-none ${
                        disablePromoCodeForm ? 'grayscale !opacity-100' : ''
                      }`}
                      onClick={() => {
                        formikProps.submitForm();
                      }}
                    >
                      Apply
                    </Button>
                  </>
                )}
              </Formik>
            </div>
            {disablePromoCodeForm && (
              <div className="text-sm">Please go back to delivery step to apply a promo code.</div>
            )}
          </div>
        )}
        <ItemRow
          valueDataCy="summaryTaxes"
          className="mb-6"
          title={
            <div className="flex flex-col">
              <span className="flex">
                {order.state === 'confirm' || order.state === 'payment' ? 'Tax' : 'Estimated Tax'}
              </span>
              {order.shippingAddress && order.shippingAddress?.countryId !== 214 && (
                <span className="flex w-48 italic text-xs font-hvLite">
                  Any applicable tariffs will be charged on delivery
                </span>
              )}
            </div>
          }
          value={formatMoney(orderTotals?.taxTotal || order.taxTotal)}
        />
        <ItemRow
          valueDataCy="summaryTotal"
          title={
            orderTotals?.totalApplicableStoreCredit > 0 || order.totalAvailableStoreCredit > 0
              ? 'Total (Before Credits)'
              : 'Total'
          }
          value={formatMoney(orderTotals?.total || order.total)}
          bold
          noBorder={order.state === 'confirm'}
          className="mb-6"
        />
        {(orderTotals?.totalApplicableStoreCredit > 0 || order.totalAvailableStoreCredit > 0) && (
          <>
            <ItemRow
              valueDataCy="appliedCredits"
              className="mb-6"
              title="Applied Credit"
              value={formatMoney(
                -1 * (orderTotals?.totalApplicableStoreCredit || order.totalApplicableStoreCredit),
              )}
            />
            <ItemRow
              valueDataCy="totalRemainingAfterCredits"
              className="mb-6"
              title="Total Remaining"
              bold
              value={formatMoney(orderTotals?.orderTotalAfterStoreCredit || order.orderTotalAfterStoreCredit)}
            />
          </>
        )}
        {!hideButton && (
          <div className="flex">
            <Button
              dataCy="checkoutBtn"
              className="!w-full"
              type="button"
              color="orange"
              onClick={onCheckoutClick}
              disabled={checkoutDisabled}
            >
              {buttonText}
            </Button>
          </div>
        )}
        {!!errorText && <ErrorMessage message={errorText} />}
      </div>
    </div>
  );
};

export default PurchaseSummary;
